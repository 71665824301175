.morris-hover {
	position: absolute;
	z-index: 10;
  
	&.morris-default-style {
	  border-radius: 10px;
	  padding: 6px;
	  color: rgba(146, 163, 185, 0.9);
	  background: rgba(255, 255, 255, 0.8);
	  border: solid 1px rgba(227, 237, 252, 0.5);
	  font-family: sans-serif;
	  font-size: 12px;
	  text-align: center;
  
	  .morris-hover-row-label {
		font-weight: bold;
		margin: 0.25em 0;
	  }
  
	  .morris-hover-point {
		white-space: nowrap;
		margin: 0.1em 0;
	  }
	}
  }