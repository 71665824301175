@keyframes smartphoto {
	from {
	  opacity: 0;
	}
  
	to {
	  opacity: 1;
	}
  }
  
  @keyframes smartphoto-img-wrap {
	from {
	  opacity: 0;
	}
  
	to {
	  opacity: 1;
	}
  }
  
  @keyframes smartphoto-inner {
	from {
	  transform: translate(0, 100px);
	}
  
	to {
	  transform: translate(0, 0);
	}
  }
  
  @keyframes smartphoto-loader {
	0% {
	  opacity: .4;
	  transform: rotate(0deg);
	}
  
	50% {
	  opacity: 1;
	  transform: rotate(180deg);
	}
  
	100% {
	  opacity: .4;
	  transform: rotate(360deg);
	}
  }
  
  @keyframes smartphoto-appear {
	0% {
	  display: none;
	  opacity: 0;
	}
  
	1% {
	  display: block;
	  opacity: 0;
	}
  
	100% {
	  display: block;
	  opacity: 1;
	}
  }
  
  @keyframes smartphoto-hide {
	0% {
	  display: block;
	  opacity: 1;
	}
  
	99% {
	  display: block;
	  opacity: 0;
	}
  
	100% {
	  display: none;
	  opacity: 0;
	}
  }
  
  .smartphoto {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: black;
	opacity: 1;
	font-family: sans-serif;
	cursor: pointer;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-ms-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
	transition: all 0.3s ease-out;
	animation-name: smartphoto;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
  
	&[aria-hidden="true"] {
	  display: none;
	}
  }
  
  .smartphoto-close {
	opacity: 0;
  }
  
  .smartphoto-count {
	display: inline-block;
	color: #FFF;
	font-size: 16px;
  }
  
  .smartphoto-header {
	display: block;
	box-sizing: border-box;
	position: fixed;
	z-index: 102;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	padding: 15px;
	background-color: rgba(0, 0, 0, 0.2);
  }
  
  .smartphoto-content {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  
  .smartphoto-dismiss {
	display: block;
	position: absolute;
	top: 15px;
	right: 10px;
	width: 20px;
	height: 20px;
	padding: 0;
	border: none;
	background-color: transparent;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0i44Os44Kk44Ok44O8XzEiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI4MzQuNjUgMjgzNC42NSIgZmlsbD0iI0ZGRiI+PHRpdGxlPmljb248L3RpdGxlPjxwYXRoIGQ9Ik0xNTc2LjQyLDE0MDYuNzYsMjc4NCwxOTkuMTlhNTYuODYsNTYuODYsMCwwLDAsMC04MC4xOGwtNzguOTItNzguOTJhNTYuODYsNTYuODYsMCwwLDAtODAuMTgsMEwxNDE3LjMyLDEyNDcuNjYsMjA5Ljc1LDQwLjA5YTU2Ljg2LDU2Ljg2LDAsMCwwLTgwLjE4LDBMNTAuNjUsMTE5YTU2Ljg2LDU2Ljg2LDAsMCwwLDAsODAuMThMMTI1OC4yMywxNDA2Ljc2LDUwLjY1LDI2MTQuMzRhNTYuODYsNTYuODYsMCwwLDAsMCw4MC4xOGw3OC45Miw3OC45MmE1Ni44Niw1Ni44NiwwLDAsMCw4MC4xOCwwTDE0MTcuMzIsMTU2NS44NiwyNjI0LjksMjc3My40NGE1Ni44Niw1Ni44NiwwLDAsMCw4MC4xOCwwbDc4LjkyLTc4LjkyYTU2Ljg2LDU2Ljg2LDAsMCwwLDAtODAuMThaIi8+PC9zdmc+);
	text-shadow: 0 1px 0 #FFF;
	color: #FFF;
	font-size: 30px;
	text-decoration: none;
	cursor: pointer;
	line-height: 1;
  }
  
  .smartphoto-body {
	position: relative;
	z-index: 102;
	width: 100%;
	height: 100%;
	margin: 0 auto;
  }
  
  .smartphoto-inner {
	position: relative;
	width: 100%;
	height: 100%;
	vertical-align: top;
  }
  
  .smartphoto-img {
	display: none;
	max-width: none;
	width: auto;
	height: auto;
	cursor: zoom-in;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: transform 0.3s ease-out;
	-webkit-user-drag: none;
  
	&.active {
	  display: block;
	}
  }
  
  .smartphoto-img-onmove {
	cursor: grab;
	cursor: -webkit-grab;
	transition: none;
  }
  
  .smartphoto-img-elasticmove {
	transition: transform 0.3s ease-out;
  }
  
  .smartphoto-img-wrap {
	display: inline-block;
	opacity: 1;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-ms-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
	animation-name: smartphoto-img-wrap;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
  }
  
  .smartphoto-img-left {
	transform: translateX(150%) !important;
  }
  
  .smartphoto-img-right {
	transform: translateX(-150%) !important;
  }
  
  .smartphoto-arrows {
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1002;
	top: 50%;
	left: 0;
	opacity: 1;
	animation-name: smartphoto-appear;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
  
	&[aria-hidden="true"] {
	  animation-name: smartphoto-hide;
	  display: none;
	}
  
	li {
	  display: block;
	  position: absolute;
	  top: 50%;
	  width: 30px;
	  height: 30px;
	  margin-top: -20px;
	  box-sizing: content-box;
	  animation-duration: 0.3s;
	  animation-timing-function: ease-out;
	  animation-name: smartphoto-appear;
  
	  &:focus {
		outline: none;
	  }
	}
  
	[aria-hidden="true"] {
	  animation-name: smartphoto-hide;
	  display: none;
	}
  
	a {
	  display: block;
	  width: 100%;
	  height: 100%;
	  text-decoration: none;
	}
  }
  
  .smartphoto-arrow-right {
	right: 0;
	padding: 5px 0;
	background-color: rgba(0, 0, 0, 0.5);
  
	a {
	  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0i44Os44Kk44Ok44O8XzEiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI4MzQuNjUgMjgzNC42NSIgZmlsbD0iI0ZGRiAiPjx0aXRsZT5pY29uPC90aXRsZT48cGF0aCBkPSJNMTgzNy44OCwxNDE3LjMyLDY0My41OSwyMjNhNzIuMjEsNzIuMjEsMCwwLDEsMC0xMDEuODJMNzQzLjgyLDIxYTcyLjIxLDcyLjIxLDAsMCwxLDEwMS44MiwwTDIwOTAuODMsMTI2Ni4xOWwxMDAuMjMsMTAwLjIzYTcyLjIxLDcyLjIxLDAsMCwxLDAsMTAxLjgyTDg0NS42NCwyODEzLjY1YTcyLjIxLDcyLjIxLDAsMCwxLTEwMS44MiwwTDY0My41OSwyNzEzLjQyYTcyLjIxLDcyLjIxLDAsMCwxLDAtMTAxLjgyWiIvPjwvc3ZnPg==);
	}
  }
  
  .smartphoto-arrow-left {
	left: 0;
	padding: 5px 0;
	background-color: rgba(0, 0, 0, 0.5);
  
	a {
	  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0i44Os44Kk44Ok44O8XzEiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI4MzQuNjUgMjgzNC42NSIgZmlsbD0iI0ZGRiI+PHRpdGxlPmljb248L3RpdGxlPjxwYXRoIGQ9Ik05OTYuNzcsMTQxNy4zMiwyMTkxLjA2LDIyM2E3Mi4yMSw3Mi4yMSwwLDAsMCwwLTEwMS44MkwyMDkwLjgzLDIxQTcyLjIxLDcyLjIxLDAsMCwwLDE5ODksMjFMNzQzLjgyLDEyNjYuMTksNjQzLjU5LDEzNjYuNDJhNzIuMjEsNzIuMjEsMCwwLDAsMCwxMDEuODJMMTk4OSwyODEzLjY1YTcyLjIxLDcyLjIxLDAsMCwwLDEwMS44MiwwbDEwMC4yMy0xMDAuMjNhNzIuMjEsNzIuMjEsMCwwLDAsMC0xMDEuODJaIi8+PC9zdmc+);
	}
  }
  
  .smartPhotoArrowHideIcon {
	display: none;
  }
  
  .smartphoto-nav {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	opacity: 1;
	animation-name: smartphoto-appear;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
  
	&[aria-hidden="true"] {
	  animation-name: smartphoto-hide;
	  display: none;
	}
  
	ul {
	  display: block;
	  overflow-x: auto;
	  list-style: none;
	  margin: 0;
	  padding: 0;
	  text-align: center;
	  white-space: nowrap;
	  -webkit-overflow-scrolling: touch;
	}
  
	li {
	  display: inline-block;
	  overflow: hidden;
	  width: 50px;
	  height: 50px;
	}
  
	a {
	  display: block;
	  width: 100%;
	  height: 100%;
	  background-color: #FFF;
	  background-position: center center;
	  background-size: cover;
	  opacity: .5;
  
	  &:focus {
		opacity: .8;
	  }
  
	  &.current {
		opacity: 1 !important;
	  }
	}
  
	img {
	  width: auto;
	  height: 100%;
	}
  }
  
  .smartphoto-list {
	list-style-type: none;
	position: absolute;
	z-index: 101;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	white-space: nowrap;
  
	li {
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  transition: all 0.3s ease-out;
  
	  &:focus {
		outline: none;
	  }
	}
  }
  
  .smartphoto-list-onmove {
	transition: all 0.3s ease-out;
  }
  
  .smartphoto-caption {
	overflow: hidden;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	padding: 0 50px;
	color: #FFF;
	font-size: 12px;
	text-align: center;
	line-height: 50px;
	white-space: nowrap;
	text-overflow: ellipsis;
  
	&:focus {
	  outline: none;
	}
  }
  
  .smartphoto-loader-wrap {
	display: block;
	position: relative;
	z-index: 103;
	width: 0;
	height: 0;
	transform: translate(50vw, 50vh);
  }
  
  .smartphoto-loader {
	position: absolute;
	z-index: 101;
	top: 0;
	left: 0;
	width: 30px;
	height: 30px;
	margin-top: -25px;
	margin-left: -25px;
	border: 8px solid #17CDDD;
	border-right-color: transparent;
	border-radius: 50%;
	animation: smartphoto-loader .5s infinite linear;
  }
  
  .smartphoto-img-clone {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	transition: all 0.3s ease-out;
  }
  
  .smartphoto-sr-only {
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0, 0, 0, 0);
  }
  
  .sidebar {
	box-shadow: none !important;
  }