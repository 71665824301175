.richText {
	position: relative;
	width: 100%;
  
	.richText-form {
	  font-family: Calibri, Verdana, Helvetica, sans-serif;
  
	  label {
		display: block;
		padding: 10px 15px;
	  }
  
	  input {
		&[type="text"], &[type="file"], &[type="number"] {
		  padding: 10px 15px;
		  border: #999999 solid 1px;
		  min-width: 200px;
		  width: 100%;
		}
	  }
  
	  select {
		padding: 10px 15px;
		border: #999999 solid 1px;
		min-width: 200px;
		width: 100%;
		cursor: pointer;
	  }
  
	  button {
		margin: 10px 0;
		padding: 10px 15px;
		background-color: #3498db;
		border: none;
		color: #FAFAFA;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	  }
	}
  
	.richText-toolbar {
	  min-height: 20px;
  
	  ul {
		margin-bottom: 0;
  
		li {
		  float: left;
		  display: block;
		  list-style: none;
  
		  a {
			display: block;
			padding: 10px 15px;
			cursor: pointer;
			-webkit-transition: background-color 0.4s;
			-moz-transition: background-color 0.4s;
			transition: background-color 0.4s;
  
			.fa, .fas, .far, svg {
			  pointer-events: none;
			}
  
			.richText-dropdown-outer {
			  display: none;
			  position: absolute;
			  top: 0;
			  left: 0;
			  right: 0;
			  bottom: 0;
			  background-color: rgba(0, 0, 0, 0.3);
			  cursor: default;
  
			  .richText-dropdown {
				position: relative;
				display: block;
				margin: 3% auto 0 auto;
				background-color: #FAFAFA;
				border: #EFEFEF solid 1px;
				min-width: 100px;
				width: 300px;
				max-width: 90%;
				-webkit-box-shadow: 0 0 5px 0 #333;
				-moz-box-shadow: 0 0 5px 0 #333;
				box-shadow: 0 0 5px 0 #333;
				border-radius: 3px;
				z-index: 9;
				overflow-y: scroll;
				max-height: 300px;
  
				.richText-dropdown-close {
				  position: absolute;
				  top: 0;
				  right: -23px;
				  background: #FFF;
				  color: #333;
				  cursor: pointer;
				  font-size: 20px;
				  text-align: center;
				  width: 20px;
				}
			  }
  
			  ul.richText-dropdown {
				list-style: none;
  
				li {
				  display: block;
				  float: none;
				  font-family: Calibri, Verdana, Helvetica, sans-serif;
  
				  a {
					display: block;
					padding: 10px 15px;
					border-bottom: #EFEFEF solid 1px;
  
					&:hover {
					  background-color: #FFFFFF;
					}
				  }
  
				  &.inline {
					margin: 10px 6px;
					float: left;
  
					a {
					  display: block;
					  padding: 0;
					  margin: 0;
					  border: none;
					  -webkit-border-radius: 50%;
					  -moz-border-radius: 50%;
					  border-radius: 50%;
					  -webkit-box-shadow: 0 0 10px 0 #999;
					  -moz-box-shadow: 0 0 10px 0 #999;
					  box-shadow: 0 0 10px 0 #999;
  
					  span {
						display: block;
						height: 30px;
						width: 30px;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						border-radius: 50%;
					  }
					}
				  }
				}
			  }
  
			  div.richText-dropdown {
				padding: 10px 15px;
			  }
			}
  
			&:hover {
			  background-color: #FFFFFF;
			}
		  }
  
		  &[data-disable="true"] {
			opacity: 0.1;
  
			a {
			  cursor: default;
			}
		  }
  
		  &:not([data-disable="true"]).is-selected .richText-dropdown-outer {
			display: block;
		  }
		}
  
		&:after {
		  display: block;
		  content: "";
		  clear: both;
		}
	  }
  
	  &:last-child {
		font-size: 12px;
	  }
  
	  &:after {
		display: block;
		clear: both;
		content: "";
	  }
	}
  
	.richText-editor {
	  padding: 20px;
	  background-color: #FFFFFF;
	  border-left: #FFFFFF solid 2px;
	  font-family: Calibri, Verdana, Helvetica, sans-serif;
	  height: 300px;
	  outline: none;
	  overflow-y: scroll;
	  overflow-x: auto;
  
	  ul, ol {
		margin: 10px 25px;
	  }
  
	  table {
		margin: 10px 0;
		border-spacing: 0;
		width: 100%;
  
		td, th {
		  padding: 10px;
		  border: #EFEFEF solid 1px;
		}
	  }
  
	  &:focus {
		border-left: #2b88ff solid 2px;
	  }
	}
  
	.richText-initial {
	  margin-bottom: -4px;
	  padding: 10px;
	  background-color: #282828;
	  border: none;
	  color: #33FF33;
	  font-family: Monospace, Calibri, Verdana, Helvetica, sans-serif;
	  max-width: 100%;
	  min-width: 100%;
	  width: 100%;
	  min-height: 400px;
	  height: 400px;
	}
  
	.richText-help {
	  float: right;
	  display: block;
	  padding: 10px 15px;
	  cursor: pointer;
	}
  
	.richText-undo, .richText-redo {
	  float: left;
	  display: block;
	  padding: 10px 15px;
	  border-right: #EFEFEF solid 1px;
	  cursor: pointer;
	}
  
	.richText-undo.is-disabled, .richText-redo.is-disabled {
	  opacity: 0.4;
	}
  
	.richText-help-popup {
	  a {
		color: #3498db;
		text-decoration: underline;
	  }
  
	  hr {
		margin: 10px auto 5px auto;
		border: none;
		border-top: #EFEFEF solid 1px;
	  }
	}
  
	.richText-list.list-rightclick {
	  position: absolute;
	  background-color: #FAFAFA;
	  border-right: #EFEFEF solid 1px;
	  border-bottom: #EFEFEF solid 1px;
  
	  li {
		padding: 5px 7px;
		cursor: pointer;
		list-style: none;
	  }
	}
  }